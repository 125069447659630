// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-notification',
//   templateUrl: './notification.component.html',
//   styleUrls: ['./notification.component.scss']
// })
// export class NotificationComponent implements OnInit {

//   showNotification = true;

//   data = {
//     message: null,
//     type: null
//   };

//   constructor() { }

//   ngOnInit() {
//   }

//   closeNotification() {
//     this.showNotification = false;
//   }

// }

import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [
    trigger('fadeSlide', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(-10px)' }))
      ])
    ])
  ]
})
export class NotificationComponent implements OnInit {

  @Input() data = { message: '', type: 'success' }; // Definição padrão
  @Input() duration = 3000; // Tempo padrão de exibição em milissegundos

  showNotification = false;

  ngOnInit() {
    if (this.data.message) {
      this.showNotification = true;
      this.autoClose();
    }
  }

  closeNotification() {
    this.showNotification = false;
  }

  private autoClose() {
    if (this.duration > 0) {
      setTimeout(() => this.closeNotification(), this.duration);
    }
  }
}