import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() pageTitle: any;

  @Input() hideOptions: boolean = false;

  @Input() showNotLoggedOptions: boolean = false;

  isSideMenuOpened = false;

  user = null;

  isAdmin = false;

  menuOpened = false;

  width = 0;

  // Define o limite da largura em que o menu deve estar fechado
  mobileThreshold = 960;

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.width = window.innerWidth;
    this.user = this.userService.getLoggedUser();
    this.isAdmin = this.user && this.user.profile == 'Administrator';
  }

  // Listen to window resize event
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = window.innerWidth;
    this.checkMenuState(); // Verifica se o menu deve ser fechado automaticamente
  }

  // Verifica se a largura da tela é suficiente para ocultar o menu
  checkMenuState() {
    if (this.width >= this.mobileThreshold) {
      // Fecha o menu quando a largura é maior que o limite (como em uma tela de desktop)
      this.menuOpened = false;
    }
  }

  openMenu() {
    this.menuOpened = !this.menuOpened;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  goHome() {
    this.router.navigate(['/']);
  }

  newCourse() {
    this.router.navigate(['/curso/novo']);
  }

  goToCourses() {
    this.router.navigate(['/lista/cursos']);
  }

  help() {
    this.router.navigate(['/ajuda']);
  }

  isUserAuthorized(profile: Array<string>) {
    return AuthService.isUserAuthorized(profile);
  }

}

// import { Component, OnInit, Input } from '@angular/core';
// import { Router } from '@angular/router';
// import { AuthService } from '../services/auth/auth.service';
// import { UserService } from '../services/user/user.service';

// @Component({
//   selector: 'app-header',
//   templateUrl: './header.component.html',
//   styleUrls: ['./header.component.scss']
// })
// export class HeaderComponent implements OnInit {

//   @Input() pageTitle: any;

//   @Input() hideOptions: boolean = false;

//   @Input() showNotLoggedOptions: boolean = false;

//   isSideMenuOpened = false;

//   user = null;

//   isAdmin = false;

//   menuOpened = false;

//   width = 0;

//   constructor(
//     private router: Router,
//     private userService: UserService
//   ) { }

//   ngOnInit() {
//     this.width = window.innerWidth;
//     this.user = this.userService.getLoggedUser();
//     this.isAdmin = this.user && this.user.profile == 'Administrator';
//   }

//   openMenu() {
//     this.menuOpened = !this.menuOpened;
//   }

//   logout() {
//     localStorage.clear();
//     this.router.navigate(['/login']);
//   }

//   goHome() {
//     this.router.navigate(['/']);
//   }

//   newCourse() {
//     this.router.navigate(['/curso/novo']);
//   }

//   goToCourses() {
//     this.router.navigate(['/lista/cursos']);
//   }

//   help() {
//     this.router.navigate(['/ajuda']);
//   }

//   isUserAuthorized(profile: Array<string>) {
//     return AuthService.isUserAuthorized(profile);
//   }

// }
