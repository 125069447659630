import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionAdminService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
  ) { }

    async getTransactionsExportByDate(startDate: string, endDate: string): Promise<void> {
        try {
            const response = await this.http.get(`${environment.api}courseTransaction/export-paid-transactions`, {
            params: { startDate, endDate },
            responseType: 'blob' // Important for handling file downloads
            }).toPromise();

            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `transactions_${startDate}_${endDate}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            this.notificationService.notification$.next({
                type: 'error',
                message: error.error.message
              });
            console.error('Error exporting transactions:', error);
        }
    }

    async getTransactionsExportByCourse(startDate: string, endDate: string, title: string): Promise<void> {
        try {
            const response = await this.http.get(`${environment.api}courseTransaction/export-all-transactions`, {
            params: { startDate, endDate, title },
            responseType: 'blob' // Important for handling file downloads
            }).toPromise();

            const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `transactions_${startDate}_${endDate}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            this.notificationService.notification$.next({
                type: 'error',
                message: error.error.message
              });
            console.error('Error exporting transactions:', error);
        }
    }
}
