import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  submitted = false;
  email = null;
  password = null;

  isLogged = false;

  companies = [];

  recoverPasswordEnabled = false;

  leadCourseId = null;
  showPassword: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    const user = this.userService.getLoggedUser();
    if (user) {
      this.router.navigate(['/dashboard']);
    }
    if (this.route.snapshot.paramMap.get('courseId')) {
      this.leadCourseId = this.route.snapshot.paramMap.get('courseId');
    }
  }

  async login() {
    this.submitted = true;

    if (this.email && this.password) {

      this.email = this.email.trim().toLowerCase();
      const result = await this.userService.login(this.email, this.password);
      if (result) {
        let subscriptionLink = localStorage.getItem('redirectCourseSubscription');

        if (subscriptionLink) {
          this.router.navigate(['/curso/inscricao/' + subscriptionLink]);
          localStorage.removeItem('redirectCourseSubscription');
          return;
        }

        if (this.leadCourseId) {
          return this.router.navigate(['/curso/inscricao/' + this.leadCourseId]);
        }
        this.router.navigate(['/dashboard']);
      }
    }
  }

  toggleRecoverPassword() {
    this.recoverPasswordEnabled = !this.recoverPasswordEnabled;
  }

  async recoverPassword() {
    await this.userService.sendPasswordRecoveryEmail(this.email);
    this.recoverPasswordEnabled = true;
  }

  register() {
    this.router.navigate(['/registrar'])
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.login();
    }
  }
}
