import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '../services/course/course.service';
import { NotificationService } from '../services/notification/notification.service';
import { StateCityService } from '../services/state-city.service';
import * as moment from 'moment';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-new-course',
  templateUrl: './new-course.component.html',
  styleUrls: ['./new-course.component.scss']
})
export class NewCourseComponent implements OnInit {
  course = {
    id: null,
    isOnline: false,
    isCoupleCourse: false,
    title: null,
    city: null,
    state: null,
    startDate: null,
    endDate: null,
    subscribeLimitDate: null,
    closedForSubscription: false,
    bookUrl: null,
    maxStudents: null,
    fullDescription: null,
    cases: [],
    amount: 0,
    paymentExternalLink: '',
    ceffId: null,
    introduction: null,
    introductionUrl: null,
    courseImgUri: null,
    teamLeaderOne: null,
    teamLeaderTwo: null,
    teamLeaderThree: null,
    teamLeaderFour: null,
    teamLeaderFive: null,
    teamLeaderSix: null,
    moreDetails: null
  };

  cases = [];

  cities = [];
  states = [];

  isEditionMode = false;

  today = new Date();

  ceffs: any[] = [];

  constructor(
    private stateCityService: StateCityService,
    private courseService: CourseService,
    private notificationService: NotificationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.states = this.stateCityService.listState();
    this.cities = this.stateCityService.listCity();
    this.ceffs = (await this.userService.listCeffs()).sort((a, b) => a.name.localeCompare(b.name));
    // this.ceffs = await this.userService.listCeffs();

    if(id) {
      this.isEditionMode = true;
      this.course = await this.courseService.getCourseById(id);
      if(this.course && this.course.cases && this.course.cases.length > 0) {
        // this.course.cases.sort((a,b) => new Date(a.teamMeetingDate).getTime() - new Date(b.teamMeetingDate).getTime())
        this.course.cases.sort((a, b) => {
          const dateA = a.teamMeetingDate ? new Date(a.teamMeetingDate).getTime() : null;
          const dateB = b.teamMeetingDate ? new Date(b.teamMeetingDate).getTime() : null;
          return (dateA || 0) - (dateB || 0);
        });
        this.course.cases.map(c => {
          this.cases.push({
            id: c.id,
            courseId: c.courseId,
            title: c.title,
            description: c.description,
            teamMeetingAddress: c.teamMeetingAddress,
            teamMeetingDate: c.teamMeetingDate,
            teamPlenaryAddress: c.teamPlenaryAddress,
            teamPlenaryDate: c.teamPlenaryDate,
            urlPdf: c.urlPdf,
            plenaryVideoUrl: c.plenaryVideoUrl
          });
        })
      }
    } else {
      this.newCase();
    }
  }

  changeIsOnline(event) {
    this.course.isOnline = event == 'true';
  }
  changeCoupleCourse(event) {
    this.course.isCoupleCourse = event == 'true';
  }

  changeState(stateId) {
    this.course.city = null;
    this.cities = this.stateCityService.getCitiesByState(stateId)
  }

  newCase() {
    this.cases.push({
      id: 0,
      courseId: null,
      title: null,
      description: null,
      teamMeetingAddress: null,
      teamMeetingDate: null,
      teamPlenaryAddress: null,
      teamPlenaryDate: null,
      urlPdf: null,
      plenaryVideoUrl: null
    });
  }

  validate() {
    if(!this.course.title) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o nome do programa'
      });
      return false;
    }
    if(!this.course.amount || this.course.amount === 0) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o preço do programa'
      });
      return false;
    }
    if(!this.course.maxStudents) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o número máximo de alunos'
      });
      return false;
    }
    if(!this.course.fullDescription) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a descrição do caso'
      });
      return false;
    }
    if(!this.course.isOnline && !this.course.state) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha o estado'
      });
      return false;
    }
    if(!this.course.isOnline && !this.course.city) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a cidade'
      });
      return false;
    }
    if(!this.course.startDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a data de início'
      });
      return false;
    }
    if(!this.course.endDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a data de término'
      });
      return false;
    }
    if(!this.course.subscribeLimitDate) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'Preencha a data de limite de inscrição'
      });
      return false;
    }
    if(moment(this.course.startDate).isAfter(this.course.endDate)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'A data de início não pode ser posterior a data de término'
      });
      return false;
    }

    if(moment(this.course.subscribeLimitDate).isAfter(this.course.endDate)) {
      this.notificationService.notification$.next({
        type: 'warning',
        message: 'A data limite de inscrição não pode ser posterior a data de término'
      });
      return false;
    }
    return this.validateCases();
  }

  validateCases() {
    if(!this.cases || this.cases.length === 0) {
      return false;
    }

    let isValid = true;
    for(let i = 0; i < this.cases.length; i++) {
      if(!this.cases[i].title) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o título do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if(!this.cases[i].description) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o descrição do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if(!this.cases[i].title) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o descrição do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if (!this.cases[i].teamMeetingAddress) {
        if (i > 0) {
          this.notificationService.notification$.next({
            type: 'warning',
            message: 'Preencha o endereço ou link da reunião de equipe do caso ' + (i + 1)
          });
          isValid = false;
          break;
        }
      }
      if (!this.cases[i].teamMeetingDate) {
        if (i > 0) {
          this.notificationService.notification$.next({
            type: 'warning',
            message: 'Preencha a data da reunião de equipe do caso ' + (i + 1)
          });
          isValid = false;
          break;
        }
        else {
            // Se é a primeira reunião de equipe, está vazio colocaremos null aqui.
            const date = new Date(1900, 0, 1, 11, 11);
            const formattedDate = date.toISOString();
            const year = formattedDate.slice(0, 4);
            const month = formattedDate.slice(5, 7);
            const day = formattedDate.slice(8, 10);
            const hour = formattedDate.slice(11, 13);
            const minute = formattedDate.slice(14, 16);
            const finalDate = `${year}-${month}-${day}T${hour}:${minute}`;
            this.cases[i].teamMeetingDate = finalDate;
          }
        }
      
      // if(!this.cases[i].teamMeetingAddress) {
      //   this.notificationService.notification$.next({
      //     type: 'warning',
      //     message: 'Preencha o endereço ou link da reunião de equipe do caso ' + (i + 1)
      //   });
      //   isValid = false;
      //   break;
      // }
      // if(!this.cases[i].teamMeetingDate) {
      //   this.notificationService.notification$.next({
      //     type: 'warning',
      //     message: 'Preencha a data da reunião de equipe do caso ' + (i + 1)
      //   });
      //   isValid = false;
      //   break;
      // }
      // if(moment(this.cases[i].teamMeetingDate).isBefore(this.course.startDate)) {
      //   this.notificationService.notification$.next({
      //     type: 'warning',
      //     message: 'A data da reunião de equipe do caso ' + (i + 1) + ' não pode ser anterior a data de início do programa'
      //   });
      //   isValid = false;
      //   break;
      // }
      // if(moment(this.cases[i].teamMeetingDate).isAfter(this.course.endDate)) {
      //   this.notificationService.notification$.next({
      //     type: 'warning',
      //     message: 'A data da reunião de equipe do caso ' + (i + 1) + ' não pode ser posterior a data de término do programa'
      //   });
      //   isValid = false;
      //   break;
      // }
      if(!this.cases[i].teamPlenaryAddress) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha o endereço ou link da plenária do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      if(!this.cases[i].teamPlenaryDate) {
        this.notificationService.notification$.next({
          type: 'warning',
          message: 'Preencha a data da plenária do caso ' + (i + 1)
        });
        isValid = false;
        break;
      }
      // if(moment(this.cases[i].teamPlenaryDate).isBefore(this.course.startDate)) {
      //   this.notificationService.notification$.next({
      //     type: 'warning',
      //     message: 'A data da reunião plenária do caso ' + (i + 1) + ' não pode ser anterior a data de início do programa'
      //   });
      //   isValid = false;
      //   break;
      // }
      // if(moment(this.cases[i].teamPlenaryDate).isAfter(this.course.endDate)) {
      //   this.notificationService.notification$.next({
      //     type: 'warning',
      //     message: 'A data da reunião plenária do caso ' + (i + 1) + ' não pode ser posterior a data de término do programa'
      //   });
      //   isValid = false;
      //   break;
      // }
    }

    return isValid;
  }

  async save() {
    if(this.validate()) {
      if(this.isEditionMode) {
        const course = await this.courseService.updateCourse(this.course);
        await this.courseService.updateAllCourseCases(this.course.id, this.cases);
        this.router.navigate(['/curso/' + course.id]);
      } else {
        const course = await this.courseService.createCourse(this.course);
        for(let courseCase of this.cases) {
          courseCase.courseId = course.id;
          const caseResult = await this.courseService.createCourseCase(courseCase);
        }
        this.router.navigate(['/curso/' + course.id]);
      }
    }
  }

  removeCase(index) {
    this.cases.splice(index, 1);
  }
}
