// import { Component } from '@angular/core';
// import { TransactionAdminService } from '../services/transactions/transactions.admin.service';
// import * as XLSX from 'xlsx';

// @Component({
//   selector: 'app-settings',
//   templateUrl: './settings.component.html',
//   styleUrls: ['./settings.component.scss']
// })
// export class SettingsComponent {
//   startDate: string = '';  // Store the start date
//   endDate: string = '';    // Store the end date
//   selectedYear: number;
//   years: number[];

//   constructor(private transactionAdminService: TransactionAdminService) { 
//     const currentYear = new Date().getFullYear();
//     this.years = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => 2000 + i);
//     this.selectedYear = currentYear; // default to current year
//   }

//   downloadExcel() {
//     // Verifica se as datas estão preenchidas
//     if (this.startDate && this.endDate) {
//       const formattedStartDate = this.formatDate(this.startDate, true);
//       const formattedEndDate = this.formatDate(this.endDate, false);
//       console.log('formattedStartDate', formattedStartDate);
//       console.log('formattedEndDate', formattedEndDate);
      
//       // Call your service with formatted dates
//       this.transactionAdminService.getTransactionsExportByDate(formattedStartDate, formattedEndDate);
//     } else {
//       alert('Por favor, preencha as datas antes de baixar o Excel.');
//     }
//   }
  
//   // formatDate(date: string, isStartDate: boolean): string {
//   //   console.log('date', date);
//   //   const dateObj = new Date(date); // Create a Date object from the input string
//   formatDate(date: string, isStartDate: boolean): string {
//     // console.log('date', date);
//     // Convertendo a string de data para um formato ISO que pode ser tratado como UTC
//     const dateParts = date.split("-"); // Divide a data no formato "YYYY-MM-DD"
//     const yearTemp = parseInt(dateParts[0], 10);
//     const monthTemp = parseInt(dateParts[1], 10) -1; // Meses no JavaScript são 0-indexados
//     const dayTemp = parseInt(dateParts[2], 10);

//     // Criando a data usando Date.UTC para garantir que seja tratada como UTC
//     const dateObj = new Date(yearTemp, monthTemp, dayTemp); 

//     // Set time based on whether it's a start or end date
//     if (isStartDate) {
//       dateObj.setHours(0, 0, 1, 0); // Set time to 00:00:01 for start date
//     } else {
//       dateObj.setHours(23, 59, 59, 0); // Set time to 23:59:59 for end date
//     }
  
//     // Manually format the date in the desired format "YYYY-MM-DDThh:mm:ss-03:00"
//     const year = dateObj.getFullYear();
//     const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
//     const day = dateObj.getDate().toString().padStart(2, '0');
//     const hours = dateObj.getHours().toString().padStart(2, '0');
//     const minutes = dateObj.getMinutes().toString().padStart(2, '0');
//     const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    
//     // Return the formatted string with timezone offset -03:00
//     return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}-03:00`;
//   }
  
// }


import { Component, OnInit } from '@angular/core';
import { TransactionAdminService } from '../services/transactions/transactions.admin.service';
import { CourseService } from '../services/course/course.service'; // Import the CourseService
import * as XLSX from 'xlsx';
import * as moment from 'moment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  startDate: string = '';  // Store the start date
  endDate: string = '';    // Store the end date
  selectedYear: number;
  years: number[];
  courses: any[] = [];
  selectedCourse: string;
  yearStartDate: string = '';
  yearEndDate: string = '';

  constructor(
    private transactionAdminService: TransactionAdminService,
    private courseService: CourseService // Inject the CourseService
  ) { 
    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: currentYear - 2000 + 1 }, (_, i) => 2000 + i);
    this.selectedYear = currentYear; // default to current year
  }

  ngOnInit(): void {
    this.onYearChange(); // Fetch courses for the default year on initialization
  }

  async onYearChange(): Promise<void> {
    console.log('Selected year:', this.selectedYear); // Log the selected year
    const minDate = moment(`${this.selectedYear}-01-01`).format('YYYY-MM-DD');
    const maxDate = moment(`${this.selectedYear}-12-31`).format('YYYY-MM-DD');
    this.yearStartDate = minDate;
    this.yearEndDate = maxDate;
    console.log('minDate:', minDate);
    console.log('maxDate:', maxDate);
    try {
      this.courses = await this.courseService.listNextCourses(minDate, maxDate);
      this.courses.sort((a, b) => a.title.localeCompare(b.title)); // Sort courses alphabetically by title
    } catch (error) {
      console.error('Error fetching courses', error);
    }
  }

  downloadExcelBasedOnData() {
    // Verifica se as datas estão preenchidas
    if (this.startDate && this.endDate) {
      console.log('startDate:', this.startDate);
      const formattedStartDate = this.formatDate(this.startDate, true);
      const formattedEndDate = this.formatDate(this.endDate, false);
      console.log('formattedStartDate', formattedStartDate);
      console.log('formattedEndDate', formattedEndDate);
      
      // Call your service with formatted dates
      this.transactionAdminService.getTransactionsExportByDate(formattedStartDate, formattedEndDate);
    } else {
      alert('Por favor, preencha as datas antes de baixar o Excel.');
    }
  }

  downloadExcelBasedOnCourse() {
    // Verifica se as datas estão preenchidas
    if (this.yearStartDate && this.yearEndDate &&this.selectedCourse) {
      const formattedStartDate = this.formatDate(this.yearStartDate, true);
      const formattedEndDate = this.formatDate(this.yearEndDate, false);
      console.log('teste 1 ');
      console.log('formattedStartDate 2 ', formattedStartDate);
      console.log('formattedEndDate 2 ', formattedEndDate);
      console.log('this.selectedCourse 2 ', this.selectedCourse);

      // Call your service with formatted dates
      this.transactionAdminService.getTransactionsExportByCourse(formattedStartDate, formattedEndDate, this.selectedCourse);
    } else {
      alert('Por favor, preencha as datas antes de baixar o Excel.');
    }
  }

  formatDate(date: string, isStartDate: boolean): string {
    const dateParts = date.split("-");
    const yearTemp = parseInt(dateParts[0], 10);
    const monthTemp = parseInt(dateParts[1], 10) - 1;
    const dayTemp = parseInt(dateParts[2], 10);

    const dateObj = new Date(yearTemp, monthTemp, dayTemp);

    if (isStartDate) {
      dateObj.setHours(0, 0, 1, 0);
    } else {
      dateObj.setHours(23, 59, 59, 0);
    }

    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const seconds = dateObj.getSeconds().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}-03:00`;
  }
}
