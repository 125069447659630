import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification/notification.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router
  ) { }

  async createCourse(newCourse) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'course', newCourse).toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async createCourseCase(newCase) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'case', newCase).toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async listNextCourses(startDate, endDate) {
    try {
      const course = await this.http.get(environment.api + 'course/getByDate?startDate=' + startDate + '&endDate=' + endDate).toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async listCourses() {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const users = await this.http.get(environment.api + 'course').toPromise();
      return users && users["data"] ? users["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return [];
    }
  }

  async listTeamLeaderCourse() {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const users = await this.http.get(environment.api + 'course/teamleader').toPromise();
      return users && users["data"] ? users["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return [];
    }
  }

  async listUserCourses(userId = null) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      // if(!loggedUser) {
      //   this.notificationService.notification$.next({
      //     type: 'error',
      //     message: 'É necessário estar logado para realizar essa operação.'
      //   });
      //   this.router.navigate(['/login']);
      //   return false;
      // }
      userId = userId ? userId : loggedUser.id
      const users = await this.http.get(environment.api + 'courseUser/getByUserId/' + userId).toPromise();
      return users && users["data"] ? users["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
    }
  }

  async listUsersAnswers(caseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const users = await this.http.get(environment.api + 'answer/getCaseAnswers/' + caseId).toPromise();
      return users && users["data"] ? users["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
    }
  }

  async getCourseById(courseId) {
    try {
      const course = await this.http.get(environment.api + 'course/getById/' + courseId).toPromise();
      return course && course["data"] ? course["data"] : null
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async getCourseCases(courseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.get(environment.api + 'case/getByCourseId/' + courseId).toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return [];
    }
  }

  async getCourseCase(courseCaseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.get(environment.api + 'case/getById/' + courseCaseId).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async getUserCaseExecution(caseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.get(environment.api + 'answer/getUserCaseAnswers/' + caseId).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async saveAnswers(answer) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'answer', answer).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async createAnswer(answer: any) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'answer', answer).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async updateAnswer(answer: any) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.put(environment.api + 'answer/' + answer.id, answer).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async registerUserOnCourse(courseUser) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      courseUser.userId = loggedUser.id;
      console.log("registering post....")
      const course = await this.http.post(environment.api + 'courseUser', courseUser).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async listCourseUsers(courseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.get(environment.api + 'courseUser/getByCourseId/' + courseId).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async updateCourse(updatedCourse) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.put(environment.api + 'course/' + updatedCourse.id, updatedCourse).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async updateAllCourseCases(courseId, cases) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.put(environment.api + 'case/course/' + courseId, cases).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async createPagseguroTransaction(courseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'courseTransaction/', {
        courseId: courseId,
        userId: loggedUser.id,
        urlCallBack: 'http://ec2-18-231-40-243.sa-east-1.compute.amazonaws.com:3010/courseTransaction/notification/transaction/' + courseId
      }).toPromise();
      return course && course["data"] ? course["data"] : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async createIuguTransaction(transaction) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'courseTransaction/', transaction).toPromise();
      return course ? course : null;
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async updateCourseTransaction(courseTransaction) {
    try {
      const loggedUser = this.userService.getLoggedUser();

      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }

      const response = await this.http.put(environment.api + 'courseTransaction/' + courseTransaction.id, courseTransaction).toPromise();
      return response && response["data"] ? response["data"] : null;

    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async deleteCourseTransaction(id) {
    try {
      const loggedUser = this.userService.getLoggedUser();

      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }

      const response = await this.http.delete(environment.api + 'courseTransaction/' + id).toPromise();
      return response && response["data"] ? response["data"] : null;

    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async getFullCourseDescription(courseId) {
    try {
      const response = await this.http.get(environment.api + 'course/getAll/' + courseId).toPromise();
      return response && response["data"] ? response["data"] : null;

    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async getFullCourse(courseId) {
    try {
      const loggedUser = this.userService.getLoggedUser();

      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }

      const response = await this.http.get(environment.api + 'courseUser/fullCourse/' + courseId).toPromise();
      return response && response["data"] ? response["data"] : null;

    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  async saveSpouseCourseUser(courseUser, transactionId) {
    try {
      const loggedUser = this.userService.getLoggedUser();
      if (!loggedUser) {
        this.notificationService.notification$.next({
          type: 'error',
          message: 'É necessário estar logado para realizar essa operação.'
        });
        this.router.navigate(['/login']);
        return false;
      }
      const course = await this.http.post(environment.api + 'courseUser/spouseUser/' + transactionId, courseUser).toPromise();
      return course && course["data"] ? course["data"] : null
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: e.error.error
      });
      return null;
    }
  }

  // AQUI DE
  // async saveAddress(courseUser, transactionId) {
  //   try {
  //     const loggedUser = this.userService.getLoggedUser();
  //     if (!loggedUser) {
  //       this.notificationService.notification$.next({
  //         type: 'error',
  //         message: 'É necessário estar logado para realizar essa operação.'
  //       });
  //       this.router.navigate(['/login']);
  //       return false;
  //     }
  //     const course = await this.http.post(environment.api + 'courseUser/spouseUser/' + transactionId, courseUser).toPromise();
  //     return course && course["data"] ? course["data"] : null
  //   } catch (e) {
  //     console.error(e);
  //     this.notificationService.notification$.next({
  //       type: 'error',
  //       message: e.error.error
  //     });
  //     return null;
  //   }
  // }

  async listCourseTemplates() {
    try {
      const course = await this.http.get(environment.api + 'courseTemplate').toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: 'Falha ao buscar templates'
      });
      return null;
    }
  }

  async listCoursesByTemplateId(templateId: any) {
    try {
      const course = await this.http.get(environment.api + 'course/getByTemplateId/' + templateId).toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: 'Falha ao buscar os programas'
      })
    }
  }

  async getTransactionsByUserId(userId: string) {
    try {
      const course = await this.http.get(environment.api + 'courseTransaction/getByUserId/' + userId).toPromise();
      return course && course["data"] ? course["data"] : []
    } catch (e) {
      console.error(e);
      this.notificationService.notification$.next({
        type: 'error',
        message: 'Falha ao buscar transações'
      });
      return null;
    }
  }

}
