import { Component, OnInit } from '@angular/core';
import { CourseService } from '../services/course/course.service';
import * as moment from 'moment';
import { StateCityService } from '../services/state-city.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {

  courses = [];
  allCourses = [];
  states = [];
  cities = [];

  filter = {
    stateId: null,
    cityId: null
  };

  isOnlineCourses = false;

  constructor(
    private stateCityService: StateCityService,
    private courseService: CourseService,
    private router: Router
  ) { }

  async ngOnInit() {
    this.states = this.stateCityService.listState();
    this.states.sort((a,b) => b.name > a.name ? -1 : 1);
    this.cities = this.stateCityService.listCity();
    let maxDate = new Date(2099, 1, 1, 1, 1, 1, 1);
    let courses = await this.courseService.listNextCourses(moment().format('YYYY-MM-DD'), moment(maxDate).format('YYYY-MM-DD'));
    courses = courses.filter(c => !c.isOnline)
    for(let i = 0; i < courses.length; i++) {
      let state = this.states.find(s => s.id == courses[i].state);
      let city = this.stateCityService.getCity(courses[i].city);
      courses[i].cityName = city.name;
      courses[i].stateName = state.name;
    }
    this.isOnlineCourses = this.router.url.indexOf('nacional') !== -1;
    if(this.isOnlineCourses) {
      this.courses = courses.filter(c => c.isOnline); 
    } else {
      this.courses = courses.filter(c => !c.isOnline);
    }
    this.allCourses = [...this.courses];
    this.cities = this.cities.filter(c => this.allCourses.filter(course => course.city == c.id).length > 0);
  }

  listCourses() {
    if(this.filter.cityId && this.filter.cityId != "all") {
      console.log("cityId:" + this.filter.cityId);
      return this.allCourses.filter((a: any) => a.city === this.filter.cityId);
    }
    return this.allCourses;
  }

  getCity(cityId) {
    const city = this.stateCityService.getCity(cityId);
    return city && city.name ? city.name : '';
  }

  getState(stateId) {
    const state = this.stateCityService.getState(stateId);
    return state && state.name ? state.name : '';
  }

  goToCourseDetails(courseId) {
    return this.router.navigate(['/lista/cursos/curso/' + courseId]);
  }

  changeState(stateId) {
    this.filter.cityId = null;
    this.filter.stateId = stateId;
    this.cities = this.stateCityService.getCitiesByState(stateId)
    if(stateId == 'all') {
      this.courses = [...this.allCourses];
      return;
    }
    this.courses = this.allCourses.filter(c => c.state == stateId);
  }

  changeCity(cityId) {
    if(cityId === 'all') {
      this.courses = [...this.allCourses];
    } else {
      this.filter.cityId = cityId;    
      this.courses = this.allCourses.filter(c => c.city == cityId);
    }
  }

  filters() {

  }

  seeCourse(id: any) {
    this.router.navigate(['/inscricao/' + id]);
  }

}
